import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Landing from '../pages/Landing'
import Dashboard from '../pages/Dashboard'
import Files from '../pages/Files'
import Kits from '../pages/Kits'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import PrivateRoute from '../pages/PrivateRoute'
const AppStack = () => {
    return (

        <BrowserRouter>
            <Routes>
                <Route  path='/' element={<Landing/>}/>
                <Route  path='/dashboard' element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                <Route  path='/dashboard/files' element={<PrivateRoute><Files/></PrivateRoute>}/>
                <Route  path='/dashboard/kits' element={<PrivateRoute><Kits/></PrivateRoute>}/>
                <Route  path='/privacy/policy' element={<PrivacyPolicy/>}/>

            </Routes>
        </BrowserRouter>

    )
}

export default AppStack