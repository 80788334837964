import React, { useState } from 'react'
import api from '../../services/api'
import Input from '../../components/Input'
import logoTkg from '../../images/png/backgroundCompanyIcon2.png'
import backgroundCompany from '../../images/png/My password-amico 1 (1).png'
import { Navigate, useNavigate } from 'react-router-dom'
import { toast,ToastContainer } from 'react-toastify'
const Landing = () => {

    const [animation,setAnimation] = useState(false)
    const [mail,setMail] = useState()
    const [password,setPassword] = useState() 
    const navigate = useNavigate()

    const handleRedirectToDashboardPage = async () => {

        if(mail == undefined || mail == ""){
            return toast.error("Informe um email")
        }

        if(password == undefined || password == ""){
            return toast.error("Informe uma senha")
        }

        setAnimation(true)
        const response = await api.post("session/storage/panel",{
            mail:mail,
            password:password
        })

        if(response.status == 200){
            localStorage.setItem("@USER_TOKEN_FACTORY",response.data.token)
            navigate("/dashboard")
        }
        setAnimation(false)
        toast.error("Usuário ou senha inválidos!")
    }

    return (
        <>
        <ToastContainer/>
        <section className='landing'>
            <div className='colum_primary'>
                <img src={backgroundCompany}/>
            </div>
            <div className='colum_secondary'>
                <img src={logoTkg}/>
                <p>Dados de acesso</p>
                <Input name="Email" onChange={(e) => setMail(e.target.value)} placeholder="Insira seu e-mail" />
                <Input name="Senha" onChange={(e) => setPassword(e.target.value)} type={"password"} placeholder="Insira sua senha" />
                <button onClick={() => handleRedirectToDashboardPage()}>{animation ? "Processando..." : "Entrar"}</button>
            </div>
        </section>
        </>
    )
}

export default Landing