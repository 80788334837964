import React,{useContext} from 'react'
import InputSearch from '../InputSearch'
import { FileContext } from '../../context/files'
const Filter = ({name,page,placeholder}) => {
    
    const {uris,handleUserFile } = useContext(FileContext)

    return(
        <div className='filter'>
            <InputSearch onChange={(e) => handleUserFile(e.target.value)} placeholder={placeholder}/>
            {String(uris).length > 0 
            
            ?
            <button style={{display:page == "dashboard" ? "none": "block" }} data-toggle="modal" data-target="#addMultiple">Imprimir</button>        
            :
            <button style={{display:page == "dashboard" ? "none": "block" }} data-toggle="modal" data-target="#add">{name}</button>

            }
        </div>

    )
}

export default Filter