import React from "react";

const PrivacyPolicy = () => {
    return (

        <div>
            <p>
                Política de Privacidade

                No App Braconi, respeitamos sua privacidade e estamos comprometidos em proteger as informações que você compartilha conosco. Informamos que nosso aplicativo não coleta, armazena ou processa qualquer tipo de dado pessoal dos usuários.

                1. Coleta de Informações Nosso aplicativo não coleta informações pessoais, como nome, e-mail, dados de localização ou qualquer outro tipo de dado identificável. Todo o uso do aplicativo é feito sem a necessidade de fornecer qualquer informação pessoal.

                2. Uso de Dados Como não coletamos nenhum dado pessoal, também não processamos ou utilizamos quaisquer informações de usuários para nenhum fim, seja ele comercial, promocional ou analítico.

                3. Compartilhamento de Informações Como não coletamos dados, não há compartilhamento de informações com terceiros.

                4. Alterações na Política de Privacidade Embora nosso aplicativo não colete dados, esta política pode ser atualizada de tempos em tempos para refletir mudanças no funcionamento do aplicativo ou nas leis aplicáveis. Recomendamos que os usuários revisem esta política periodicamente para se manterem informados.

                5. Contato Se você tiver qualquer dúvida sobre nossa política de privacidade, entre em contato conosco através do e-mail codergustavo@gmail.com.
            </p>
        </div>

    )
}

export default PrivacyPolicy