import React, { createContext, useState } from 'react'
import api from '../services/api'

export const FileContext = createContext({})

function FileProvider({ children }) {

    const [files, setFiles] = useState()
    const [qrCode,setQrCode] = useState()
    const [uris,setUris] = useState([]) 
    const [link,setLink] = useState()
    const [linkPdf,setLinkPdf] = useState()
    const handleUserFile = async (name) => {
        const response = await api.get(`files/get/${name}`)

        if(response.status == 200){
            setFiles(response.data)
        }
    }
    const get = async () => {
        const response = await api.get("panel/get/all/files")
        if (response.status == 200) {
            setFiles(response.data)
        }
    }
    const getMultiples = (data,image,uri,sku) => {
        // Verifica se já existe algum item com a mesma 'data'
        const exists = uris.some(item => item.data === data);
    
        if (exists) {
            // Se existir, remove o item
            const updatedUris = uris.filter(item => item.data !== data);
            setUris(updatedUris);
            return false;
        } 
    
        // Caso contrário, adiciona o novo item
        setUris([...uris, { data, image,uri,sku }]);
    };
    const storage = async () => {
    }
    const edit = async () => {
    }
    const getQrCode = async (id) => {
        const getImage = await api.get(`panel/get/qrcode/${id}`)
        setQrCode(getImage.data.uri)
    }
    const getLink = async (id) => {
        setLink(id)
    }
    const getManualLink = async (link) => {
        setLinkPdf(link)
    }

    return (
        <FileContext.Provider value={{
            get,
            getMultiples,
            uris,
            setFiles,
            files,
            storage,
            edit,
            getQrCode,
            handleUserFile,
            getLink,
            link,
            qrCode,
            getManualLink,
            linkPdf
        }}>
            {children}
        </FileContext.Provider>
    )

}

export default FileProvider